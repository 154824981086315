// import { isEmpty } from "lodash";
import {
  universal,
  speciality,
  country,
  stateUrl,
  cityUrl,
  members,
} from "../../js/path";

export default {
  data() {
    return {
      mainid: this.$route.params.id,
      form: {
        first_name: "",
        last_name: "",
        speciality: "",
        email: "",
        city: "",
        state: "",
        country: "",
        member_type: "",
        country_code: "",
        is_verified: 0,
        reg_no: "",
        reg_date: "",
        dob: "",
        internal_user: 0,
      },
      profile_image: "",
      profile_image_url: "",
      edit: {
        profile_image_url: null,
      },
      pageNo: null,
      mobile_number: "",
      activeTab: "non-member",
      value: null,
      getSpeciality: [],
      getCountry: [],
      getState: [],
      getCity: [],
      allCity: [],
      getMemberType: [],
      qualification: "",
      message: "",
      speciality: "Dermatology",
      member_status_fil: "",
      member_type_fil: "",
      country: "",
      state: "",
      city: "",
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Member Name",
          class: "c_touchpt_header",
        },
        {
          key: "speciality",
          class: "c_touchpt_header",
        },
        {
          key: "country",
          class: "c_touchpt_header",
        },
        {
          key: "state",
          label: "State",
          class: "c_touchpt_header",
        },
        {
          key: "city",
          label: "City",
          class: "c_touchpt_header",
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          class: "c_touchpt_header",
        },
        {
          key: "email",
          class: "c_touchpt_header",
        },
        {
          key: "member_tag",
          label: "Member Tag",
          class: "c_touchpt_header",
        },
        {
          key: "is_verified",
          label: "Member Status",
          class: "c_touchpt_header",
        },
        {
          key: "member_type",
          label: "Member Type",
          class: "c_touchpt_header",
        },
        {
          key: "internal_user",
          label: "Internal User Status",
          class: "c_touchpt_header",
        },
        {
          key: "edit",
        },
        {
          key: "share",
        },
      ],
      verified_options: [
        {
          text: "No Status",
          value: "0",
        },
        {
          text: "Verified",
          value: "1",
        },
        {
          text: "Not Verified",
          value: "2",
        },
        {
          text: "Processing",
          value: "3",
        },
        {
          text: "Not Valid User",
          value: "5",
        },
      ],
      member_type_options: [
        {
          text: "Doctor",
          value: "doctor",
        },
        {
          text: "Ayush Doctor",
          value: "ayush_doctor",
        },
        {
          text: "Retired not practicing doctor",
          value: "retired_not_practicing_doctor",
        },
        {
          text: "PG Student",
          value: "pg_student",
        },
        {
          text: "UG Student",
          value: "ug_student",
        },
        {
          text: "Dentist",
          value: "dentist",
        },
        {
          text: "Health Care Administrator",
          value: "health_care_administrator",
        },
        {
          text: "Nurses",
          value: "nurses",
        },
        {
          text: "Pharmacist",
          value: "pharmacist",
        },
        {
          text: "Allied Health Care Worker",
          value: "allied_health_care_worker",
        },
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Psychologists",
          value: "psychologists",
        },
        {
          text: "Others",
          value: "other",
        },
      ],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      currentPage: 1,
      tableData: [],
      key: 0,
      params: "&page=1",
      form2: {
        address: "",
        city: "",
        country: "",
        currently_working: 0,
        designation: "",
        end_date: "",
        formate_end_date: "",
        formate_start_date: "",
        id: "",
        member_id: "",
        universal_workplace_info_ref_no: "",
        place_of_work: "",
        start_date: "",
        state: "",
        work_place_type: "",
      },
      form4: {
        universal_academic_info_ref_no: "",
        course_name: "",
        currently_pursuing: 0,
        degree_name: "",
        end_date: "",
        formate_end_date: "",
        formate_start_date: "",
        id: "",
        institute_master_ref_no: "",
        institute_name: "",
        member_id: "",
        other_course_name: "",
        other_institute_name: "",
        qualification: "",
        qualifiation_detail_master_ref_no: "",
        start_date: "",
      },
      allCountry: [],
      workplacelist: [],
      allWorkPlaceCity: [],
      doctor_work_place: [],
      doctor_academics: [],
      is_work_place_modal: false,
      allWorkPlaceState: [],
      is_academic_modal: false,
      institute: [],
      academic_details: [],
      academic_details_fields: [
        {
          key: "id",
          label: "Universal ID",
        },
        {
          key: "course_name",
          label: "Course Name",
          class: "w-200",
        },
        {
          key: "other_course_name",
          label: "Other Course Name",
          class: "w-200",
        },
        {
          key: "institute_name",
          label: "Institute Name",
          class: "w-200",
        },
        {
          key: "other_institute_name",
          label: "Other Institue Name",
          class: "w-200",
        },
        {
          key: "formate_start_date",
          label: "Start Date",
        },
        {
          key: "formate_end_date",
          label: "End Date",
        },
        {
          key: "currently_pursuing",
          label: "Currently Pursuing",
        },
        {
          key: "edit",
        },
      ],
      workplace_details: [],
      workplace_details_fields: [
        {
          key: "id",
          label: "Universal ID",
        },
        {
          key: "designation",
        },
        {
          key: "place_of_work",
          label: "Place of work",
        },
        {
          key: "work_place_type",
          label: "Workplace Type",
        },
        {
          key: "country",
        },
        {
          key: "state",
        },
        {
          key: "city",
        },
        {
          key: "formate_start_date",
          label: "Start Date",
        },
        {
          key: "formate_end_date",
          label: "End Date",
        },
        {
          key: "currently_working",
          label: "Currently Working",
        },
        {
          key: "edit",
        },
      ],
    };
  },
  computed: {
    allSpecialityWithAll() {
      const allOption = { id: "all", title: "All" };
      return [{ id: "null", title: "Null" }, allOption, ...this.getSpeciality];
    },
  },
  methods: {
    getWorkPlace(index) {
      let workplaceDetails = this.workplace_details.findIndex(
        (e) => e.id === index
      );
      this.is_work_place_modal = true;
      const data = this.workplace_details[workplaceDetails];
      if (data.address != null || data.address != "") {
        this.form2.address = data.address;
      }
      if (data.start_date != null || data.start_date != "") {
        this.form2.start_date = data.start_date;
      }
      if (data.formate_start_date != null || data.formate_start_date != "") {
        this.form2.formate_start_date = data.formate_start_date;
      }
      if (data.work_place_type != null || data.work_place_type != "") {
        this.form2.work_place_type = data.work_place_type;
      }
      if (data.designation != null || data.designation != "") {
        this.form2.designation = data.designation;
      }
      if (data.place_of_work != null || data.place_of_work != "") {
        this.form2.place_of_work = data.place_of_work;
      }
      if (data.country != null || data.country != "") {
        this.form2.country = data.country;
        this.getStateWorkPlacesByCountry();
      }
      if (data.state != null || data.state != "") {
        this.form2.state = data.state;
        this.getWorkPlaceCitiesByState();
      }
      if (data.city != null || data.city != "") {
        this.form2.city = data.city;
      }
      if (data.end_date != null || data.end_date != "") {
        this.form2.end_date = data.end_date;
      }
      if (data.currently_working != null || data.currently_working != "") {
        this.form2.currently_working = data.currently_working;
      }
      if (
        data.universal_workplace_info_ref_no != null ||
        data.universal_workplace_info_ref_no != ""
      ) {
        this.form2.universal_workplace_info_ref_no =
          data.universal_workplace_info_ref_no;
      }
    },
    getAcademics(index) {
      let academicIndex = this.academic_details.findIndex(
        (e) => e.id === index
      );
      this.is_academic_modal = true;
      const data = this.academic_details[academicIndex];
      if (
        data.universal_academic_info_ref_no != null ||
        data.universal_academic_info_ref_no != ""
      ) {
        this.form4.universal_academic_info_ref_no =
          data.universal_academic_info_ref_no;
      }
      if (data.course_name != null || data.course_name != "") {
        this.form4.course_name = data.course_name;
      }
      if (data.currently_pursuing != null || data.currently_pursuing != "") {
        this.form4.currently_pursuing = data.currently_pursuing;
      }
      if (data.degree_name != null || data.degree_name != "") {
        this.form4.degree_name = data.degree_name;
      }
      if (data.end_date != null || data.end_date != "") {
        this.form4.end_date = data.end_date;
      }
      if (data.formate_end_date != null || data.formate_end_date != "") {
        this.form4.formate_end_date = data.formate_end_date;
      }
      if (data.formate_start_date != null || data.formate_start_date != "") {
        this.form4.formate_start_date = data.formate_start_date;
      }
      if (data.id != null || data.id != "") {
        this.form4.id = data.id;
      }
      if (
        data.institute_master_ref_no != null ||
        data.institute_master_ref_no != ""
      ) {
        this.form4.institute_master_ref_no = data.institute_master_ref_no;
      }
      if (data.institute_name != null || data.institute_name != "") {
        this.form4.institute_name = data.institute_name;
      }
      if (data.member_id != null || data.member_id != "") {
        this.form4.member_id = data.member_id;
      }
      if (data.currently_pursuing != null || data.currently_pursuing != "") {
        this.form4.currently_pursuing = data.currently_pursuing;
      }
      if (data.other_course_name != null || data.other_course_name != "") {
        this.form4.other_course_name = data.other_course_name;
      }
      if (
        data.other_institute_name != null ||
        data.other_institute_name != ""
      ) {
        this.form4.other_institute_name = data.other_institute_name;
      }
      if (data.qualification != null || data.qualification != "") {
        this.form4.qualification = data.qualification;
      }
      if (
        data.qualifiation_detail_master_ref_no != null ||
        data.qualifiation_detail_master_ref_no != ""
      ) {
        this.form4.qualifiation_detail_master_ref_no =
          data.qualifiation_detail_master_ref_no;
      }
      if (data.start_date != null || data.start_date != "") {
        this.form4.start_date = data.start_date;
      }
    },
    async updateWorkplace() {
      try {
        this.$store.commit("loader/updateStatus", true);
        let url = universal.updateUniversalWorkplace;
        let dataAppend = new FormData();
        dataAppend.append(
          "universal_workplace_info_ref_no",
          this.form2.universal_workplace_info_ref_no ?? ""
        );
        dataAppend.append("id", this.$route.params.id);
        dataAppend.append("work_place_type", this.form2.work_place_type ?? "");
        dataAppend.append("designation", this.form2.designation ?? "");
        dataAppend.append("place_of_work", this.form2.place_of_work ?? "");
        dataAppend.append("country", this.form2.country ?? "");
        dataAppend.append("state", this.form2.state ?? "");
        dataAppend.append("city", this.form2.city ?? "");
        dataAppend.append("start_date", this.form2.start_date ?? "");
        if (this.form2.currently_working == "1") {
          dataAppend.append("end_date", "");
          dataAppend.append("currently_working", 1);
        } else {
          dataAppend.append("currently_working", 0);
          dataAppend.append("end_date", this.form2.end_date ?? "");
        }
        dataAppend.append("address", this.form2.address ?? "");
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.fetchWorkplace(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateAcademic() {
      try {
        this.$store.commit("loader/updateStatus", true);
        let url = universal.updateUniversalAcademics;
        let dataAppend = new FormData();
        if (this.form4.universal_academic_info_ref_no) {
          dataAppend.append(
            "universal_academic_info_ref_no",
            this.form4.universal_academic_info_ref_no ?? ""
          );
        }
        dataAppend.append("universal_id", this.$route.params.id);
        dataAppend.append("start_date", this.form4.start_date);
        if (this.form4.currently_pursuing == "1") {
          dataAppend.append("currently_pursuing", 1);
          dataAppend.append("end_date", "");
        } else {
          dataAppend.append("currently_pursuing", 0);
          dataAppend.append("end_date", this.form4.end_date ?? "");
        }
        if (this.form4.institute_name == "Other") {
          dataAppend.append("institute_name", this.form4.other_institute_name);
        } else {
          dataAppend.append("institute_name", "");
        }
        if (this.form4.course_name == "Others") {
          dataAppend.append("other_course_name", this.form4.other_course_name);
        } else {
          dataAppend.append("other_course_name", "");
        }
        if (this.form4.qualification) {
          dataAppend.append("qualification", this.form4.qualification);
        }
        if (this.form4.institute_master_ref_no) {
          dataAppend.append(
            "institute_master_ref_no",
            this.form4.institute_master_ref_no
          );
        }
        if (this.form4.qualifiation_detail_master_ref_no) {
          dataAppend.append(
            "qualification_detail_master_ref_no",
            this.form4.qualifiation_detail_master_ref_no
          );
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.fetchAcademics(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchAcademics(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = universal.getUniversalDoctorAcademics + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.academic_details = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchWorkplace(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = universal.getUniversalDoctorWorkplace + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.workplace_details = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    updateInstituteRefNo() {
      const selectedInstitute = this.form4.institute_name;
      const matchingInstitute = this.institute.find(
        (item) => item.institute_name === selectedInstitute
      );

      if (matchingInstitute) {
        this.form4.institute_master_ref_no =
          matchingInstitute.institute_master_ref_no;
      } else {
        this.form4.institute_master_ref_no = ""; // Reset if no matching institute found
      }
    },
    updateQulificationRefNo() {
      const selectedQualification = this.form4.course_name;
      const matchingQualification = this.qualification.find(
        (item) => item.course_name === selectedQualification
      );

      if (matchingQualification) {
        this.form4.qualification =
          matchingQualification.qualification_master_ref_no;
        this.form4.qualifiation_detail_master_ref_no =
          matchingQualification.qualification_detail_master_ref_no;
      } else {
        this.form4.qualification = ""; // Reset if no matching institute found
        this.form4.qualifiation_detail_master_ref_no = "";
      }
    },
    closeModal() {
      this.submitted = false;
    },
    resetWorkPlaceCity() {
      this.form2.city = "";
      this.getWorkPlaceCitiesByState();
    },
    async fetchWorkplaceList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getworkplaceList;
        const data = await this.getRequest(url);
        if (data.status) {
          this.workplacelist = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async getWorkPlaceCitiesByState() {
      console.log("innn**");
      this.form2.city = "";
      if (this.form2.state != null) {
        const url =
          cityUrl.city + "/" + this.form2.country + "/" + this.form2.state;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allWorkPlaceCity = data.response;
        }
      }
    },
    addAcademics() {
      this.is_academic_modal = true;
      this.form4.universal_academic_info_ref_no = "";
      this.form4.course_name = "";
      this.form4.currently_pursuing = "";
      this.form4.degree_name = "";
      this.form4.end_date = "";
      this.form4.formate_end_date = "";
      this.form4.formate_start_date = "";
      this.form4.id = "";
      this.form4.institute_master_ref_no = "";
      this.form4.institute_name = "";
      this.form4.member_id = "";
      this.form4.currently_pursuing = 0;
      this.form4.other_course_name = "";
      this.form4.other_institute_name = "";
      this.form4.qualification = "";
      this.form4.qualifiation_detail_master_ref_no = "";
      this.form4.start_date = "";
    },
    addWorkPlace() {
      this.is_work_place_modal = true;
      this.form2.address = "";
      this.form2.start_date = "";
      this.form2.formate_start_date = "";
      this.form2.work_place_type = "";
      this.form2.designation = "";
      this.form2.place_of_work = "";
      this.form2.country = "";
      this.form2.state = "";
      this.form2.city = "";
      this.form2.end_date = "";
      this.form2.currently_working = 0;
      this.form2.universal_workplace_info_ref_no = "";
    },
    resetWorkPlaceStateAndCity() {
      this.form2.state = "";
      this.form2.city = "";
      this.getStateWorkPlacesByCountry();
    },
    async getStateWorkPlacesByCountry() {
      this.form2.state = "";
      this.form2.city = "";
      if (this.form2.country != null) {
        const url = stateUrl.state + "/" + this.form2.country;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allWorkPlaceState = data.response;
        }
      }
    },
    async getCitiesByState() {
      if (
        (this.country != null || this.$route.query.country) &&
        (this.state != null || this.$route.query.state)
      ) {
        const url = `${cityUrl.city}/${
          this.country
            ? this.country
            : this.$route.query.country
            ? this.$route.query.country
            : ""
        }/${
          this.state
            ? this.state
            : this.$route.query.state
            ? this.$route.query.state
            : ""
        }`;
        console.log(url);
        const data = await this.getRequest(url);
        if (data.status) {
          this.allCity = data.response;
        }
      }
    },
    handleWorkplaces(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitWorkplaces();
    },
    handleAcademics(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitAcademics();
    },
    handleSubmitWorkplaces() {
      this.updateWorkplace();
      this.$nextTick(() => {
        this.$bvModal.hide("bvModal1");
      });
      this.submitted = false;
    },
    handleSubmitAcademics() {
      this.updateAcademic();
      this.$nextTick(() => {
        this.$bvModal.hide("bvModal2");
      });
      this.submitted = false;
    },
    copyURL(speciality, id) {
      if (speciality && id) {
        const dynamicLink = `${this.$hostLink}/directory/${speciality}/${id}`;
        const input = document.createElement("input");
        input.value = dynamicLink;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "success",
          title: "Dynamic link copied to clipboard!",
        });
      } else {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Speciality is empty!",
        });
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
          this.currentPage = 1;
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
        this.currentPage = 1;
      } else {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterSpeciality(val) {
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
      }
    },
    filterMemberStatus(val) {
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
      }
    },
    filterMemberTypeStatus(val) {
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
      }
    },
    filterCountry(val) {
      this.state = "";
      this.city = "";
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
        this.getStatesByCountry();
      }
    },
    filterState(val) {
      this.city = "";
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
        this.getCitiesByState();
      }
    },
    filterCity(val) {
      console.log(val);
      if (val) {
        this.fetchData(this.activeTab);
      }
    },
    onChangeProfileListing() {
      this.fetchData(this.activeTab);
    },
    async fetchData(txt) {
      console.log("in");
      let url = null;
      if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = `${universal.fetchUniversal}?filter=${txt}&search=${
            this.filter
          }&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&search=" +
          // this.filter +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.country != null && this.country != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.state != null && this.state != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.speciality != null && this.speciality != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.city != null && this.city != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (
          this.member_status_fil != null &&
          this.member_status_fil != ""
        ) {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.member_type_fil != null && this.member_type_fil != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else {
          url = universal.fetchUniversal + "?filter=" + txt;
        }
      } else if (txt == "member") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = `${universal.fetchUniversal}?filter=${txt}&search=${
            this.filter
          }&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&search=" +
          // this.filter +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.country != null && this.country != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.state != null && this.state != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.speciality != null && this.speciality != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.city != null && this.city != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (
          this.member_status_fil != null &&
          this.member_status_fil != ""
        ) {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.member_type_fil != null && this.member_type_fil != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else {
          url = universal.fetchUniversal + "?filter=" + txt;
        }
      } else if (txt == "non-member") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = `${universal.fetchUniversal}?filter=${txt}&search=${
            this.filter
          }&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&search=" +
          // this.filter +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.country != null && this.country != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.state != null && this.state != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.speciality != null && this.speciality != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.city != null && this.city != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (
          this.member_status_fil != null &&
          this.member_status_fil != ""
        ) {
          console.log(this.member_status_fil);
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else if (this.member_type_fil != null && this.member_type_fil != "") {
          url = `${
            universal.fetchUniversal
          }?filter=${txt}&speciality=${encodeURIComponent(
            this.speciality
          )}&state=${encodeURIComponent(
            this.state
          )}&country=${encodeURIComponent(
            this.country
          )}&city=${encodeURIComponent(
            this.city
          )}&member_status_fil=${encodeURIComponent(
            this.member_status_fil
          )}&member_type_fil=${encodeURIComponent(this.member_type_fil)}`;
          // universal.fetchUniversal +
          // "?filter=" +
          // txt +
          // "&ref_filter=" +
          // this.ref_filter +
          // "&speciality=" +
          // encodeURIComponent(this.speciality) +
          // "&country=" +
          // encodeURIComponent(this.country) +
          // "&state=" +
          // encodeURIComponent(this.state) +
          // "&city=" +
          // encodeURIComponent(this.city) +
          // "&member_status_fil" +
          // encodeURIComponent(this.member_status_fil);
        } else {
          url = universal.fetchUniversal + "?filter=" + txt;
        }
      } else {
        url += "?";
      }
      this.getUrl();
      url += this.params;
      if (this.profile_listing) {
        url += "&show_lead_profile_members=1";
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    getUrl() {
      var url = new URL(window.location);
      if (url.pathname.includes("edit-universal-member")) {
        url.searchParams.delete("filter");
        url.searchParams.delete("page", 1);
      } else {
        if (this.activeTab == null || this.activeTab == "") {
          this.activeTab = "processing";
        }
        url.searchParams.has("filter")
          ? url.searchParams.set("filter", this.activeTab)
          : url.searchParams.append("filter", this.activeTab);
        if (this.filter != null && this.filter != "") {
          console.log(this.filter, "this.filter");
          url.searchParams.has("search")
            ? url.searchParams.set("search", this.filter)
            : url.searchParams.append("search", this.filter);
        } else {
          url.searchParams.delete("search");
        }
        if (this.speciality != null && this.speciality != "") {
          url.searchParams.has("speciality")
            ? url.searchParams.set("speciality", this.speciality)
            : url.searchParams.append("speciality", this.speciality);
        } else {
          url.searchParams.delete("speciality");
        }
        if (this.member_status_fil != null && this.member_status_fil != "") {
          url.searchParams.has("member_status_fil")
            ? url.searchParams.set("member_status_fil", this.member_status_fil)
            : url.searchParams.append(
                "member_status_fil",
                this.member_status_fil
              );
        } else {
          url.searchParams.delete("member_status_fil");
        }
        if (this.member_type_fil != null && this.member_type_fil != "") {
          url.searchParams.has("member_type_fil")
            ? url.searchParams.set("member_type_fil", this.member_type_fil)
            : url.searchParams.append("member_type_fil", this.member_type_fil);
        } else {
          url.searchParams.delete("member_type_fil");
        }
        if (this.country != null && this.country != "") {
          url.searchParams.has("country")
            ? url.searchParams.set("country", this.country)
            : url.searchParams.append("country", this.country);
        } else {
          url.searchParams.delete("country");
        }
        if (this.state != null && this.state != "") {
          url.searchParams.has("state")
            ? url.searchParams.set("state", this.state)
            : url.searchParams.append("state", this.state);
        } else {
          url.searchParams.delete("state");
        }
        if (this.city != null && this.city != "") {
          url.searchParams.has("city")
            ? url.searchParams.set("city", this.city)
            : url.searchParams.append("city", this.city);
        } else {
          url.searchParams.delete("city");
        }
        if (this.currentPage != null && this.currentPage != "") {
          url.searchParams.has("page")
            ? url.searchParams.set("page", this.currentPage)
            : url.searchParams.append("page", this.currentPage);
        } else {
          url.searchParams.set("page", 1);
        }
        url.search = url.searchParams;
        url = url.toString();
        history.pushState({}, null, url);
      }
    },
    async fetchSpeciality() {
      const url = speciality.fetchAllActiveSpeciality;
      const data = await this.getRequest(url);
      if (data.status) {
        this.getSpeciality = data.response.data;
      }
    },
    async fetchAllCountry() {
      const url = country.fetchCountry;
      const data = await this.getRequest(url);
      if (data.status) {
        this.getCountry = data.response;
        console.log(this.getCountry, data);
      }
    },
    async getStatesByCountry() {
      if (this.country != null || this.$route.query.country) {
        const url =
          stateUrl.state + "/" + this.country || this.$route.query.country;
        const data = await this.getRequest(url);
        if (data.status) {
          this.getState = data.response;
        }
      }
    },
    async fetchAllStatesByCountry(country) {
      if (country) {
        const url = stateUrl.state + "/" + country;
        const data = await this.getRequest(url);
        if (data.status) {
          this.getState = data.response;
        }
      }
    },
    async fetchAllCityByState(country, state) {
      if (country && state) {
        const url = cityUrl.city + "/" + country + "/" + state;
        const data = await this.getRequest(url);
        if (data.status) {
          this.getCity = data.response;
        }
      }
    },
    changeCountry(country) {
      this.form.state = "";
      this.form.city = "";
      this.fetchAllStatesByCountry(country);
    },
    changeState(country, state) {
      this.form.city = "";
      this.fetchAllCityByState(country, state);
    },
    async fetchSingleUniversal(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = universal.fetchUniversal + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;

          if (
            responseData.first_name != null &&
            responseData.first_name != ""
          ) {
            this.form.first_name = responseData.first_name;
          }

          if (responseData.last_name != null && responseData.last_name != "") {
            this.form.last_name = responseData.last_name;
          }

          if (responseData.email != null && responseData.email != "") {
            this.form.email = responseData.email;
          }

          if (responseData.mobile_number) {
            this.form.mobile_number = responseData.mobile_number;
          }

          if (responseData.city) {
            this.form.city = responseData.city;
          }

          if (responseData.state) {
            this.form.state = responseData.state;
          }

          if (responseData.state && responseData.country) {
            this.fetchAllCityByState(responseData.country, responseData.state);
          }

          if (responseData.country_code) {
            this.form.country_code = responseData.country_code;
          }

          if (responseData.country) {
            this.fetchAllStatesByCountry(responseData.country);
            this.form.country = responseData.country;
          }

          if (responseData.speciality) {
            this.form.speciality = responseData.speciality;
          }

          if (responseData.is_verified) {
            this.form.is_verified = responseData.is_verified;
          }

          if (responseData.reg_no) {
            this.form.reg_no = responseData.reg_no;
          }

          if (responseData.reg_date) {
            this.form.reg_date = responseData.reg_date;
          }
          if (responseData.dob) {
            this.form.dob = responseData.dob;
          }

          if (responseData.profile_image) {
            this.profile_image_url = responseData.profile_image;
          }

          if (responseData.dropdown_options) {
            responseData.dropdown_options.map((item) => {
              if (item) {
                this.getMemberType.push({
                  value: item.key,
                  label: item.label,
                });
              }
            });
            if (
              responseData.member_type != null &&
              responseData.member_type != ""
            ) {
              this.form.member_type = responseData.member_type;
              console.log(responseData.member_type);
            }
          }
          if (responseData.internal_user) {
            this.form.internal_user = true;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          this.$store.commit("loader/updateStatus", true);
          let url = universal.fetchUniversal + "/" + this.$route.params.id;
          let dataAppend = new FormData();
          if (this.profile_image) {
            dataAppend.append("profile_image", this.profile_image);
          }
          if (this.form.city) {
            dataAppend.append("city", this.form.city);
          } else {
            dataAppend.append("city", "");
          }
          for (var key in this.form) {
            if (key != "city" && key != "profile_image") {
              dataAppend.append(key, this.form[key]);
            }
          }
          dataAppend.append("_method", "put");
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.go(-1);
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    readFile(e, txt) {
      if (txt == "profile_image") {
        this.profile_image = e.target.files[0];
        if (this.profile_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.profile_image.reset();
          this.profile_image_url = "";
          this.edit.profile_image_url = "";
          this.profile_image = "";
          return false;
        } else {
          this.profile_image_url = URL.createObjectURL(this.profile_image);
          this.edit.profile_image_url = "";
        }
        return true;
      }
    },
    async fetchInstitute() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getInstitute;
        const data = await this.getRequest(url);
        if (data.status) {
          this.institute = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchQualification() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = universal.getQualification;
        const data = await this.getRequest(url);
        if (data.status) {
          this.qualification = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async storeNotes(val, message) {
      try {
        const url = universal.storeNotes;
        let dataAppend = new FormData();
        dataAppend.append("member_id", val);
        dataAppend.append("mci_note", message);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.isClickedMciReferrel = false;
      } catch (err) {
        console.log(err);
      }
    },
    renderClickableLink(message) {
      const clickableText = message.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      );
      return clickableText;
    },
  },
  watch: {
    tableData(v) {
      if (v) {
        return (this.tableData = v);
      }
    },
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  async created() {
    if (this.$route.name == "edit-universal") {
      this.fetchSpeciality();
      this.fetchAllCountry();
      // this.fetchAllStates();
      this.fetchSingleUniversal(this.$route.params.id);
      this.fetchInstitute();
      this.fetchQualification();
      this.fetchAcademics(this.$route.params.id);
      this.fetchWorkplace(this.$route.params.id);
      this.fetchWorkplaceList();
    }
  },
  mounted() {
    this.activeTab = "non-member";
    if (this.$route.name == "universal") {
      this.fetchSpeciality();
      this.fetchAllCountry();
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.search) {
        this.filter = this.$route.query.search;
      }
      if (this.$route.query.speciality) {
        this.speciality = this.$route.query.speciality;
      }
      if (this.$route.query.member_status_fil) {
        this.member_status_fil = this.$route.query.member_status_fil;
      }
      if (this.$route.query.member_type_fil) {
        this.member_type_fil = this.$route.query.member_type_fil;
      }
      if (this.$route.query.country) {
        this.country = this.$route.query.country;
        this.getStatesByCountry();
      }
      if (this.$route.query.state) {
        this.state = this.$route.query.state;
        this.getCitiesByState();
      }
      if (this.$route.query.city) {
        this.city = this.$route.query.city;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
  },
};
